



















import Vue from "vue";
import store from "@/store";
import { IMedia } from "@/utils/types";
import PreviousEvents from "@/components/Events/PreviousEvents/PreviousEvents.vue";
import Banner from "@/components/Home/Banner/Banner.vue";

export default Vue.extend({
  name: "About",
  components: {
    Banner,
    PreviousEvents,
  },
  computed: {
    profile() {
      return store.state.profile;
    },
    img() {
      return store.state.profile.media?.find(
        (i: IMedia) => i.collection_name == "profile2"
      )?.original_url;
    },
  },
});
